<template>
	<v-container fluid>
		<v-card>
			<v-card-title class="pt-6 pb-0 px-6">
				<span class="headline font-weight-light mb-5">Page SEO</span>
			</v-card-title>
			<v-card-text class="px-6">
				<v-row no-gutters>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Page URL:</p>
						<v-text-field
							v-model="url"
							:loading="isSearching"
							placeholder="Enter the page URL"
							:error-messages="errors.url"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Page Title tag:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<v-text-field
							v-else
							v-model="title"
							:disabled="isSearching"
							placeholder="Enter the page title"
							:error-messages="errors.title"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Meta Description Content:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<v-text-field
							v-else
							v-model="description"
							:disabled="isSearching"
							placeholder="Enter the page description"
							:error-messages="errors.description"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Keywords:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<v-text-field
							v-else
							v-model="keyword"
							:disabled="isSearching"
							@keyup.enter="changeKeywords()"
							placeholder="Enter the page keywords"
							:error-messages="errors.keyword"
						></v-text-field>
						<div v-if="selectedKeywords">
							<v-chip
								v-for="item in selectedKeywords"
								:key="item"
								class="chip mr-2"
								close
								@click:close="removeKeyword(item)"
							>
								{{ item }}
							</v-chip>
						</div>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Leads:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<v-text-field
							v-else
							v-model="lead"
							:disabled="isSearching"
							@keyup.enter="changeLeads()"
							placeholder="Enter the page leads"
							:error-messages="errors.lead"
						></v-text-field>
						<div v-if="selectedLeads">
							<v-chip
								v-for="item in selectedLeads"
								:key="item"
								class="chip mr-2"
								close
								@click:close="removeLead(item)"
							>
								{{ item }}
							</v-chip>
						</div>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">FAQs:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<Search
							:initialData="faqs"
							:url="`/${$store.state.app.projectId}/faqs/search`"
							@search-result="updateFaqs($event)"
						></Search>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Testimonies:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<Search
							:initialData="testimonies"
							:url="`/${$store.state.app.projectId}/testimonies/search`"
							@search-result="updateTestimonies($event)"
						></Search>
					</v-col>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Redirect to:</p>
						<v-skeleton-loader
							v-if="isSearching"
							type="list-item-two-line"
						></v-skeleton-loader>
						<v-text-field
							v-else
							v-model="redirectedTo"
							:disabled="isSearching"
							placeholder="Enter the page redirection URL"
							:error-messages="errors.redirectedTo"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pa-6 card-actions">
				<UnoSaveButton
					@click.native="savePage()"
					@primary-button-click="homePage()"
					primary-button-text="Move To Home Page"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
// ACTION BUTTONS
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import Search from '@/components/Search'
import _ from 'lodash'

export default {
	components: {
		UnoSaveButton,
		Search,
	},
	data() {
		return {
			isSaving: false,
			isSearching: false,
			url: null,
			title: null,
			description: null,
			keyword: null,
			lead: null,
			redirectedTo: null,
			selectedKeywords: [],
			selectedLeads: [],
			faqs: [],
			testimonies: [],
			errorMessages: null,
			errors: {
				url: null,
				description: null,
				title: null,
				keyword: null,
				redirectedTo: null,
				lead: null,
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	watch: {
		url() {
			if (this.url !== '') this.debouncedURL()
		},
	},
	created() {
		// debounce registering
		this.debouncedURL = _.debounce(this.findPage, 300)
	},
	methods: {
		homePage() {
			this.$router.push({
				name: 'home',
			})
		},
		clearSeoData() {
			this.title = null
			this.description = null
			this.selectedLeads = []
			this.selectedKeywords = []
			this.redirectedTo = null
			this.faqs = []
			this.testimonies = []
		},
		setSeoData(data) {
			this.title = data.title
			this.description = data.description
			this.selectedLeads = data.leadTags
			this.selectedKeywords = data.keywords
			this.redirectedTo = data.redirectedTo
			this.testimonies = data.testimonies
			this.faqs = data.faqs
		},
		changeKeywords() {
			this.selectedKeywords = [...this.selectedKeywords, this.keyword]
			this.keyword = ''
		},
		changeLeads() {
			this.selectedLeads = [...this.selectedLeads, this.lead]
			this.lead = ''
		},
		findPage() {
			this.isSearching = true
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/pages/search`,
				params: {
					url: `${this.url}`,
				},
			})
				.then((res) => {
					this.isSearching = false
					if (res.status == 200 && res.data.success) {
						let data = res.data.data
						this.setSeoData(data)
						this.errorMessages = null
					} else {
						return (this.errorMessages =
							'This page is not available')
					}
				})
				.catch((error) => {
					console.log(error)
					this.isSearching = false
					this.isSaving = false
					this.clearSeoData()
				})
		},
		savePage() {
			this.isSaving = true
			const projectId = this.$store.state.app.projectId
			let payload = {
				title: this.title && this.title.length > 0 ? this.title : null,
				description:
					this.description && this.description.length > 0
						? this.description
						: null,
				keywords: this.selectedKeywords ? this.selectedKeywords : [],
				leadTags: this.selectedLeads ? this.selectedLeads : [],
				redirectedTo:
					this.redirectedTo && this.redirectedTo.length > 0
						? this.redirectedTo
						: null,
				url: this.url,
				faqs: this.faqs.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
				testimonies: this.testimonies.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
			}
			this.axios({
				method: 'put',
				url: `/${projectId}/pages`,
				data: payload,
			})
				.then((res) => {
					this.isSaving = false
					if (res.status == 200 && res.data.success) {
						let data = res.data.data
						this.setSeoData(data)
						this.alert.message = res.data.message
					} else {
						//
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		updateFaqs(faqs) {
			this.faqs = faqs
		},
		updateTestimonies(testimonies) {
			this.testimonies = testimonies
		},
		removeLead: function (itemName) {
			let items = this.selectedLeads
			let indexToSplice = _.findIndex(items, (item) => item === itemName)

			if (indexToSplice !== undefined) {
				items.splice(indexToSplice, 1)
			}

			this.selectedLeads = items
		},
		removeKeyword: function (itemName) {
			let items = this.selectedKeywords
			let indexToSplice = _.findIndex(items, (item) => item === itemName)

			if (indexToSplice !== undefined) {
				items.splice(indexToSplice, 1)
			}

			this.selectedKeywords = items
		},
	},
}
</script>

<style scoped>
.label {
	font-size: 15px;
	color: black;
}
.headline {
	color: #303f9f !important;
	font-size: 24px !important;
}
.underline {
	border-bottom: 3px solid #8bc34a;
	margin-top: 10px;
	width: 40px;
	display: block;
}
</style>
